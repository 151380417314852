@import 'src/css/globals.scss';
@import 'src/css/tools/_tools.font-size';
@import 'src/css/tools/_tools.no-user-select';
@import 'src/css/tools/_tools.au-icon';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/sass-mq/mq';

$text-color: $AU_blue_shade_2;

$section_bg-color: $AU_gray_shade_3;
$section_expanded_bg-color: rgba(#000000, 0.6);

.container {
  width: 100%;
}

%lvl {
  background-color: $section_bg-color;
  &:before {
    color: $text-color;
    top: 2px;
    padding-right: 9px;
    font-size: 16px;
    position: relative;
  }
}

.head {
  display: flex;
  align-items: baseline;
  margin: 6px 0 3px;
  cursor: pointer;
  padding-right: 5px;
  @include no-user-select();

  &.lvl-1 {
    @extend %lvl;
  }

  &.lvl-2 {
    @extend %lvl;
  }

  &.lvl-3 {
    @extend %lvl;
  }

  &.lvl-4 {
    @extend %lvl;
  }

  &.lvl-5 {
    @extend %lvl;
  }

  &.viewing {
    background-color: $AU_gray_shade_3;
  }

  a.link {
    display: block;
    font-weight: 400;
    color: $text-color;
    position: relative;
    &:hover {
      text-decoration: none;
    }
  }

  .folder {
    margin-left: 6px;
    &:before {
      font-size: 16px;
      @include au-icon("\26");
    }
  }

  .doc {
    margin-left: 6px;
    &:before {
      font-size: 16px;
      @include au-icon("\e029");
    }
  }
}

.name {
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
}

.name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  font-weight: 400;
  color: $text-color;
  position: relative;
  overflow: hidden;
  &.lvl-2 {
    @include mq($until: mobile-ls) {
      word-break: break-all;
      max-width: 190px;
    }
  }
  @include mq($from: desktop) {
    min-height: 24px;
    margin-top: 5px;
  }
}

.common {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 16px;

  &.lvl-1 {
    padding-left: $toc-level-indent;
  }
  &.lvl-2 {
    padding-left: $toc-level-indent + $toc-link-level-indent;
  }
  &.lvl-3 {
    padding-left: $toc-level-indent + $toc-link-level-indent * 2;
  }
  &.lvl-4 {
    padding-left: $toc-level-indent + $toc-link-level-indent * 3;
  }
  &.lvl-5 {
    padding-left: $toc-level-indent + $toc-link-level-indent * 4;
  }
}

.icon_container {
  composes: common;
  &:before {
    @include au-icon("\30");
  }

}
.icon_container_expanded {
  composes: common;
  &:before {
    @include au-icon("\32");
  }
}

.icon {
  color: $text-color;

  &:before {
    position: relative;
    font-size: 16px;
    padding-right: 8px;
    top: -1px;
  }
}

.children {
  display: none;

  &.expanded {
    display: block;
    @include mq($from: mobile-ls) {
      width: 350px;
    }
    .expanded {
      background-color: unset;
    }
  }

  > div:not(.container) {
    background-color: $AU_gray_shade_3;
  }
}
