/* ==========================================================================
   #CROP
   ========================================================================== */
@use "sass:math";

// A list of cropping ratios that get generated as modifier classes.

$inuit-crops: (
  (2:1),
  (4:3),
  (16:9),
) !default;



/**
 * Provide a cropping container in order to display media (usually images)
 * cropped to certain ratios.
 *
 * 1. Set up a positioning context in which the image can sit.
 * 2. This is the crucial part: where the cropping happens.
 */

.o-crop {
  position: relative; /* [1] */
  display: block;
  overflow: hidden; /* [2] */
}


  /**
   * Apply this class to the content (usually `img`) that needs cropping.
   *
   * 1. Image’s default positioning is top-left in the cropping box.
   * 2. Make sure the media doesn’t stop itself too soon.
   */

  .o-crop__content {
    position: absolute;
    top:  0; /* [1] */
    left: 0; /* [1] */
    max-width: none; /* [2] */
  }


  /**
   * We can position the media in different locations within the cropping area.
   */

  .o-crop__content--right {
    right: 0;
    left: auto;
  }

  .o-crop__content--bottom {
    top: auto;
    bottom: 0;
  }

  .o-crop__content--center {
    top:  50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }



/* stylelint-disable */

/* Crop-ratio variants.
   ========================================================================== */

/**
 * Generate a series of crop classes to be used like so:
 *
 *   <div class="o-crop  o-crop--16:9">
 *
 */

@each $crop in $inuit-crops {

  @each $antecedent, $consequent in $crop {

    @if (type-of($antecedent) != number) {
      @error "`#{$antecedent}` needs to be a number."
    }

    @if (type-of($consequent) != number) {
      @error "`#{$consequent}` needs to be a number."
    }

    .o-crop--#{$antecedent}\:#{$consequent} {
      padding-bottom: math.div($consequent, $antecedent) * 100%;
    }

  }

}

/* stylelint-enable */
