@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';

$default-font-color: #324661;
$default-font-family: $global-font-sans;
$skeleton_screen-color: $AU_gray_shade_3;
$opacity: 0.1;
$topic-color: $AU_gray_shade_2;
$light_block-color: $visited;
$dark_block-color: $AU_blue_shade_1;

$light_block-width: 350px;
$dark_block-width: 350px;
$light_block-height: 43px;
$dark_block-height: 100vh;
$light_block-position: 0 0;
$dark_block-position: 0 43px;
$light_block-skeleton: linear-gradient($light_block-color $light_block-height, transparent 0);
$dark_block-skeleton: linear-gradient($dark_block-color $dark_block-height, transparent 0);

$modify-width: 277px;
$download-width: 123px;
$modify-height: 15px;
$modify-position: calc(100vw - 466px) 31px;
$modify_tablet-position: 400px 18px;
$modify_mobile-position: 400px 18px;
$download-position: calc(100vw - 144px) 31px;
$download_tablet-position: 400px 29px;
$download_mobile-position: 400px 29px;
$modify-skeleton: linear-gradient($AU_gray_shade_3 $modify-height, transparent 0);

$modify-position: calc(100vw - 466px) 31px;
$modify_tablet-position: 24px 79px;
$download-position: calc(100vw - 144px) 31px;
$download_tablet-position: 24px 113px;
$modify-skeleton: linear-gradient($AU_gray_shade_3 $modify-height, transparent 0);

$topic-width: 277px;
$topic_mobile-width: 200px;
$topic-height: 20px;
$topic-position: 374px 94px;
$topic_tablet-position: 24px 184px;
$topic-skeleton: linear-gradient($topic-color $topic-height, transparent 0);

$subtopic-width: calc(100vw - 427px);
$subtopic_ls-width: calc(100vw - 418px);
$subtopic_tablet-width: calc(100vw - 48px);
$subtopic_two-width: calc(100vw - 726px);
$subtopic_two_ls-width: calc(100vw - 518px);
$subtopic_two_tablet-width: calc(100vw - 168px);
$subtopic_two_mobile-width: calc(100vw - 125px);
$subtopic_four-width: calc(100vw - 958px);
$subtopic_four_ls-width: calc(100vw - 618px);
$subtopic_four_tablet-width: calc(100vw - 418px);
$subtopic_four_mobile-width: calc(100vw - 165px);
$subtopic-height: 15px;
$subtopic-position: 374px 159px;
$subtopic_tablet-position: 24px 246px;
$subtopic_two-position: 374px 185px;
$subtopic_two_tablet-position: 24px 282px;
$subtopic_three-position: 374px 223px;
$subtopic_three_tablet-position: 24px 330px;
$subtopic_four-position: 374px 249px;
$subtopic_four_tablet-position: 24px 366px;
$subtopic-skeleton: linear-gradient($skeleton_screen-color $subtopic-height, transparent 0);

$header-width: 262px;
$header-height: 15px;
$header-position: 43px 61px;
$header_two-position: 43px 113px;
$header-skeleton: linear-gradient(rgba($skeleton_screen-color, $opacity) $header-height, transparent 0);

$subpoint-width: 178px;
$subpoint-height: 15px;
$subpoint-position: 43px 87px;
$subpoint-skeleton: linear-gradient(rgba($skeleton_screen-color, $opacity) $header-height, transparent 0);

$dropdown_toc-width: 100vw;
$dropdown_toc-height: 46px;
$dropdown_toc-position: 0 0;
$dropdown_toc-skeleton: linear-gradient($light_block-color $dropdown_toc-height, transparent 0);

.container {
  color: $default-font-color;
  font-family: $default-font-family;
  display: flex;
  position: relative;

  &:empty::before {
    @include mq($from: tablet-ls) {
      content: "";
      display: block;
      background-repeat: no-repeat;
      width: 350px;
      height: 100vh;
      position: absolute;

      background-image:
        $light_block-skeleton,
        $dark_block-skeleton;

      background-size:
        $light_block-width $light_block-height,
        $dark_block-width $dark_block-height;

      background-position:
        $light_block-position,
        $dark_block-position;
    }

    @include mq($until: tablet-ls) {
      content: "";
      display: block;
      background-repeat: no-repeat;
      width: 100vw;
      height: 46px;
      position: absolute;

      background-image:
        $dropdown_toc-skeleton;

      background-size:
        $dropdown_toc-width $dropdown_toc-height;

      background-position:
        $dropdown_toc-position;
    }
  }

  &:empty::after {
    @include mq($from: tablet-ls) {
      content: "";
      display: block;
      background-repeat: no-repeat;
      width: 100vw;
      height: 100vw;

      background-image:
        $header-skeleton,
        $subpoint-skeleton,
        $header-skeleton,
        $modify-skeleton,
        $modify-skeleton,
        $topic-skeleton,
        $subtopic-skeleton,
        $subtopic-skeleton,
        $subtopic-skeleton,
        $subtopic-skeleton;

      background-size:
        $header-width $header-height,
        $subpoint-width $subpoint-height,
        $header-width $header-height,
        $modify-width $modify-height,
        $download-width $modify-height,
        $topic-width $topic-height,
        $subtopic_ls-width $subtopic-height,
        $subtopic_two_ls-width $subtopic-height,
        $subtopic_ls-width $subtopic-height,
        $subtopic_four_ls-width $subtopic-height;

      background-position:
        $header-position,
        $subpoint-position,
        $header_two-position,
        $modify-position,
        $download-position,
        $topic-position,
        $subtopic-position,
        $subtopic_two-position,
        $subtopic_three-position,
        $subtopic_four-position;
    }

    @include mq($from: desktop) {
      content: "";
      display: block;
      background-repeat: no-repeat;
      width: 100vw;
      height: 100vh;
      z-index: 1;

      background-image:
        $header-skeleton,
        $subpoint-skeleton,
        $header-skeleton,
        $modify-skeleton,
        $modify-skeleton,
        $topic-skeleton,
        $subtopic-skeleton,
        $subtopic-skeleton,
        $subtopic-skeleton,
        $subtopic-skeleton;

      background-size:
        $header-width $header-height,
        $subpoint-width $subpoint-height,
        $header-width $header-height,
        $modify-width $modify-height,
        $download-width $modify-height,
        $topic-width $topic-height,
        $subtopic-width $subtopic-height,
        $subtopic_two-width $subtopic-height,
        $subtopic-width $subtopic-height,
        $subtopic_four-width $subtopic-height;

      background-position:
        $header-position,
        $subpoint-position,
        $header_two-position,
        $modify-position,
        $download-position,
        $topic-position,
        $subtopic-position,
        $subtopic_two-position,
        $subtopic_three-position,
        $subtopic_four-position;
    }

    @include mq($until: tablet-ls) {
      content: "";
      display: block;
      background-repeat: no-repeat;
      width: 100vw;
      height: calc(100vh - 46px);
      z-index: 1;

      background-image:
        $modify-skeleton,
        $modify-skeleton,
        $topic-skeleton,
        $subtopic-skeleton,
        $subtopic-skeleton,
        $subtopic-skeleton,
        $subtopic-skeleton;

      background-size:
        $modify-width $modify-height,
        $download-width $modify-height,
        $topic-width $topic-height,
        $subtopic_tablet-width $subtopic-height,
        $subtopic_two_tablet-width $subtopic-height,
        $subtopic_tablet-width $subtopic-height,
        $subtopic_four_tablet-width $subtopic-height;

      @include mq($until: tablet) {
        background-size:
          $modify-width $modify-height,
          $download-width $modify-height,
          $topic_mobile-width $topic-height,
          $subtopic_tablet-width $subtopic-height,
          $subtopic_two_mobile-width $subtopic-height,
          $subtopic_tablet-width $subtopic-height,
          $subtopic_four_mobile-width $subtopic-height;
      }

      background-position:
        $modify_tablet-position,
        $download_tablet-position,
        $topic_tablet-position,
        $subtopic_tablet-position,
        $subtopic_two_tablet-position,
        $subtopic_three_tablet-position,
        $subtopic_four_tablet-position;
    }
  }
}
