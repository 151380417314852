@import 'src/css/globals.scss';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/sass-mq/mq';
@import 'src/css/tools/tools.au-icon';

$modify-width: 277px;
$download-width: 123px;
$modify-height: 15px;
$modify-position: calc(100vw - 450px) 18px;
$modify_tablet-position: 400px 18px;
$modify_mobile-position: 400px 18px;
$download-position: calc(100vw - 150px) 18px;
$download_tablet-position: 400px 29px;
$download_mobile-position: 400px 29px;
$modify-skeleton: linear-gradient($AU_gray_shade_3 $modify-height, transparent 0);

$last_modified-color: rgba($AU_blue_shade_3, 0.6);
$download-color: $AU_blue_shade_3;
$nav-bar-height: 54px;

$skeleton_screen-color: $AU_gray_shade_2;
$skeleton_second_screen-color: $AU_gray_shade_3;

.hat {
  font-family: $global-font-base;
  margin: 40px 0 32px 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  @include mq($until: tablet) {
    flex-direction: column;
    align-items: flex-start;
  }
  @include mq(tablet, tablet-ls) {
    max-width: 632px;
  }
  @include mq($from: tablet-ls) {
    max-width: 888px;
  }
}

.lastmodified {
  color: $last_modified-color;
  @include font-size(16px, 27px);
  @include mq(tablet, desktop) {
    @include font-size(15px, 25px);
  }
  @include mq($from: desktop) {
    @include font-size(14px, 24px);
  }
}

.delimiter {
  @include mq($from: tablet) {
    padding: 0 4px;
  }
}

.swagger {
  background: unset;
  color: $AU_blue_shade_0;
  margin-top: 2px;
  &:hover {
    color: $secondary_btn;
    background: unset;
  }
  &:before {
    @include au-icon("\e03f");
    font-size: 24px;
    left: 0;
    color: unset;
  }
}

.doc {
  @include mq(mobile, tablet) {
    display: block;
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  max-height: calc(100vh - #{$nav-bar-height});
  @include mq($from: tablet) {
    overflow-y: hidden; // so only the markdown document will scroll
    flex: 1 0;
  }
  align-items: center;
}

.tablet {
  padding: 0 58px;
  .label {
    display: none;
  }
  @include mq($until: tablet) {
    padding: 0 48px;
  }
}

.mobile {
  padding: 0 58px;
  flex-direction: column;
  align-items: flex-start;
  .buttons {
    margin-bottom: 24px;
  }
  @include mq($until: tablet) {
    padding: 0 48px;
  }
}

.markdown {
  &:before {
    @include au-icon("\e021");
  }
}

.buttons {
  @include mq($until: tablet) {
    margin-top: 5px;
    margin-bottom: 30px;
  }
  display: flex;
  flex-direction: row;
}

.skeleton_container {
  width: 100%;
  padding-top: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  @include mq($until: tablet) {
    max-width: 612px;
  }
  @include mq(tablet, tablet-ls) {
    max-width: 632px;
  }
  @include mq($from: tablet-ls) {
    max-width: 888px;
  }
}

.skeleton_buttons {
  display: flex;
  align-items: center;
}

.skeleton {
  height: 15px;
  border-radius: 13px;
}

.skeleton_one {
  height: 24px;
  width: 24px;
  border-radius: 30px;
  background-color: $skeleton_screen-color;
  margin-right: 6px;
}

.skeleton_two {
  composes: skeleton;
  width: 100px;
  background-color: $skeleton_second_screen-color;
}

.skeleton_three {
  composes: skeleton;
  width: 277px;
  background-color: $skeleton_second_screen-color;
}