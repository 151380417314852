@use "sass:math";
@import "src/css/globals.scss";

.container {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.msg {
  font-family: $global-font-sans;
  font-size: math.div(24px, 13px) * 1em;
  line-height: math.div(32px, 12px);
  font-weight: 600;
}

.caveat {
  font-family: $global-font-sans;
  font-size: math.div(12px, 13px) * 1em;
  line-height: math.div(17px, 12px);
  font-weight: 600;
}
