/* ==========================================================================
   #ICON
   ========================================================================== */

/* Au Font Icons */

[data-icon]:before {
 font-family: "au-font" !important;
 content: attr(data-icon);
 font-style: normal !important;
 font-weight: normal !important;
 font-variant: normal !important;
 text-transform: none !important;
 speak: none;
 line-height: 1;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
 font-family: "au-font" !important;
 font-style: normal !important;
 font-weight: normal !important;
 font-variant: normal !important;
 text-transform: none !important;
 speak: none;
 line-height: 1;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
}

.icon-arrow:before {
 content: "\64";
}
.icon-check:before {
 content: "\67";
}
.icon-chiclet:before {
 content: "\68";
}
.icon-delete:before {
 content: "\69";
}
.icon-edit:before {
 content: "\6a";
}
.icon-expand-arrow:before {
 content: "\6c";
}
.icon-search:before {
 content: "\72";
}
.icon-polygon:before {
 content: "\73";
}
.icon-plus:before {
 content: "\74";
}
.icon-minimize-screen:before {
 content: "\77";
}
.icon-maximize-screen:before {
 content: "\78";
}
.icon-harsh-braking:before {
 content: "\4e";
}
.icon-idling:before {
 content: "\4f";
}
.icon-harsh-acceleration:before {
 content: "\50";
}
.icon-abs:before {
 content: "\61";
}
.icon-zoom-out:before {
 content: "\62";
}
.icon-zoom-in:before {
 content: "\63";
}
.icon-x-wo-circle:before {
 content: "\65";
}
.icon-x:before {
 content: "\66";
}
.icon-warning:before {
 content: "\6b";
}
.icon-user:before {
 content: "\6d";
}
.icon-geofence-event:before {
 content: "\6e";
}
.icon-airbag:before {
 content: "\6f";
}
.icon-battery:before {
 content: "\70";
}
.icon-break:before {
 content: "\71";
}
.icon-hood-open:before {
 content: "\75";
}
.icon-seat-belt:before {
 content: "\76";
}
.icon-service:before {
 content: "\79";
}
.icon-temp:before {
 content: "\7a";
}
.icon-tire-pressure:before {
 content: "\41";
}
.icon-transmission:before {
 content: "\42";
}
.icon-trunk-open:before {
 content: "\43";
}
.icon-uncheck:before {
 content: "\44";
}
.icon-unknown:before {
 content: "\46";
}
.icon-unplugged:before {
 content: "\47";
}
.icon-plugged:before {
 content: "\48";
}
.icon-open-door:before {
 content: "\49";
}
.icon-oil:before {
 content: "\4a";
}
.icon-light-on:before {
 content: "\4b";
}
.icon-key:before {
 content: "\4c";
}
.icon-idling-event:before {
 content: "\4d";
}
.icon-engine:before {
 content: "\51";
}
.icon-filter:before {
 content: "\52";
}
.icon-fuel:before {
 content: "\53";
}
.icon-caret-down:before {
 content: "\45";
}
.icon-caret-left:before {
 content: "\54";
}
.icon-caret-right:before {
 content: "\55";
}
.icon-caret-up:before {
 content: "\56";
}
.icon-eye:before {
 content: "\57";
}
.icon-eye-slash:before {
 content: "\58";
}
.icon-angle-left:before {
 content: "\5a";
}
.icon-angle-right:before {
 content: "\30";
}
.icon-angle-up:before {
 content: "\31";
}
.icon-angle-down:before {
 content: "\32";
}
.icon-bars:before {
 content: "\33";
}
.icon-sort-asc:before {
 content: "\34";
}
.icon-sort-desc:before {
 content: "\35";
}
.icon-exclamation-circle:before {
 content: "\36";
}
.icon-exclamation-triangle:before {
 content: "\37";
}
.icon-hand-paper-o:before {
 content: "\38";
}
.icon-plus-square:before {
 content: "\39";
}
.icon-minus-square:before {
 content: "\21";
}
.icon-square-o:before {
 content: "\22";
}
.icon-check-square:before {
 content: "\24";
}
.icon-check-mark:before {
 content: "\23";
}
