@import 'src/css/globals.scss';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';

$text-color: #FFFFFF;
$tile-border-color: #4A90E2;

$skeleton_screen-color: $AU_gray_shade_3;
$skeleton_text-color: $AU_gray_shade_2;

$tile-width-mobile: calc(100vw - 48px); // shared
$tile-width: 368px;
$tile-width-tablet: 348px;
$tile-width-desktop: 394px;

$tile-height: 162px;
$tile_two-height: 141px;
$tile_three-height: 183px;
$tile-margin: 24px;

$tile-skeleton: linear-gradient($skeleton_screen-color $tile-height, transparent 0);


@mixin width($n, $tile-width, $tile-width-desktop, $tile-width-tablet, $tile-width-mobile) {
  @include mq($until: tablet) {
    width: $tile-width-mobile;
  }
  @include mq(tablet, tablet-ls) {
    width: calc((#{$tile-width-tablet} + #{$tile-margin}) * #{$n});
  }
  @include mq(tablet-ls, large-desktop) {
    width: calc((#{$tile-width-desktop} + #{$tile-margin}) * #{$n});
  }
  @include mq($from: large-desktop) {
    width: calc((#{$tile-width} + #{$tile-margin}) * #{$n});
  }
}

@mixin tiles($n, $tile-width) {
  @include mq($until: tablet) {
    max-width: calc(100vw - 26px);
  }
  @include mq($from: tablet) {
    max-width: calc((#{$tile-width} + #{$tile-margin}) * #{$n});
  }
}

.container {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
}

.policy {
  justify-content: flex-start;
  padding-left: 20px;
}

.splash {
  display: flex;
  flex: 0 0 auto;
  text-align: center;
  color: $text-color;
  font-family: $global-font-sans;
  background: $AU_blue_shade_0;
  margin-bottom: 18px;

  @include mq(tablet, desktop){
    height: 220px;
  }
  @include mq($from: desktop){
    height: 380px;
  }

  h1 {
    position: absolute;
    font-weight: 600;
    left: calc(50% - 75px);

    @include mq($until: tablet) {
      @include font-size(20px, 27px);
    }
    @include mq($until: desktop) {
      @include font-size(28px, 33px);
      top: 75%;
    }
    @include mq($from: desktop) {
      @include font-size(39px, 53px);
      font-family: $global-font-sans;
      left: calc(50% - 100px);
      top: 70%;
    }

    &:before {
      position: absolute;
      right: -64px;
      content: " ";
      background: url('../../images/tmc_cloud_color.svg');
      background-size: contain;
      display: inline-block;

      @include mq($until: desktop) {
        width: 230px;
        height: 170px;
        line-height: 170px;
        right: -45px;
        top: calc(-50% - 144px);
      }
      @include mq($from: desktop) {
        width: 320px;
        height: 260px;
        line-height: 260px;
        top: calc(-50% - 212px);
      }
    }
  }
}

.wrapper {
  display: flex;
  flex: 1 1 auto;
  position: relative;
}

/* IE 10+, Edge fallback styles */
_:-ms-lang(x) {
  .wrapper {
    clip-path: unset;
    margin: 0;

    h1 {
      @include mq(tablet-ls, desktop) {
        margin-left: -16px;
      }
      @include mq($from: desktop) {
        margin-left: -32px;
      }
    }
  }
  .grid {
    background: linear-gradient(357deg, #125A7D -15%, #041327 60%);
  }
  .city {
    background-image: url('../../images/hero_banner_ie.jpg');
    @include mq($from: desktop) {
      background-size: contain;
    }
    @include mq($from: desktop) {
      background-size: auto 320px;
      background-position: bottom right;
    }
  }
}

.content {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  margin: 0 auto;
}

.getting-started {
  $tile-width: 368px;
  $tile-width-tablet: 348px;
  $tile-width-desktop: 394px;

  .content {
    @include mq($until: desktop) {
      @include width(2, $tile-width, $tile-width-desktop, $tile-width-tablet, $tile-width-mobile);
    }
    @include mq(desktop, large-desktop) {
      @include width(3, $tile-width, $tile-width-desktop, $tile-width-tablet, $tile-width-mobile);
    }
    @include mq($from: large-desktop) {
      @include width(4, $tile-width, $tile-width-desktop, $tile-width-tablet, $tile-width-mobile);
    }
  }

  .tiles {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 35px;
    }

    div[title] {
      overflow: auto;
      white-space: normal;
      @include font-size(20px, 28px);
    }
}

.tilescontainer {
  flex: 1 1 auto;
}

.services {
  $tile-width: 290px;

  .tiles {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    @include mq($until: tablet) {
      margin: $tile-margin auto;
    }
    @include mq($from: tablet) {
      margin: 35px auto;
    }
    @include mq($until: tablet) {
      @include tiles(1, $tile-width);
    }
    @include mq(tablet, tablet-ls) {
      @include tiles(2, $tile-width);
    }
    @include mq(tablet-ls, desktop) {
      @include tiles(3, $tile-width);
    }
    @include mq(desktop, x-large-desktop) {
      @include tiles(4, $tile-width);
    }
    @include mq($from: x-large-desktop) {
      @include tiles(5, $tile-width);
    }
    > div {
      flex: 0 1 auto;
    }
  }
}

.skeleton {
  width: 100%;
}

.skeleton_text {
  border-radius: 13px;
  height: 20px;
  max-width: 550px;
  background-color: $skeleton_text-color;
  margin-left: 12px;
  @include mq($until: tablet) {
    margin-right: 12px;
  }
}

.skeleton_tiles {
  display: flex;
  margin-top: 35px;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.skeleton_tile {
  height: 165px;
  background-color: $skeleton_screen-color;
  margin: 0 12px 24px;
  @include mq($until: tablet) {
    width: $tile-width-mobile;
  }
  @include mq(tablet, tablet-ls) {
    width: $tile-width-tablet;
  }
  @include mq(tablet-ls, large-desktop) {
    width: $tile-width-desktop;
  }
  @include mq($from: large-desktop) {
    width: $tile-width;
  }
}
