@import 'src/css/globals.scss';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/@au/core/src/components/elements/au_button.module.scss';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

// Much of this is needed to override SwaggerUI - https://github.com/swagger-api/swagger-ui
$font_color: $AU_blue_shade_2;
$hr_border_color: $AU_blue_shade_4;
$expanded_hr_border_color: $AU_blue_shade_5;
$box_border_color: $AU_blue_shade_3;
$description_font_color: $AU_blue_shade_2;
$bubble_color: $AU_blue_shade_4;
$label_color: $AU_blue_shade_4;
$summary_background: $AU_gray_shade_3;
$open_summary_background: $hover;
$get_method_color: $link;
$patch_method_color: $AU_blue_shade_4;
$put_method_color: $secondary_btn;
$post_method_color: $primary_btn;
$delete_method_color: $alert;
$deprecated_method_color: $AU_gray_shade_1;
$select_background_color: $AU_blue_shade_7;
$selection_bar_background_color: $AU_gray_shade_3;
$dropdown_bg-color: #FFFFFF;
$models_container_bg-color: $AU_gray_shade_3;
$tab_border-color: $primary_btn;
$response_markdown-color: $font_color;
$response_markdown_bg-color: rgba(#41444e, 0.1);
$field-color: #1D8700;
$value-color: #B36406;
$code-label: #383F3E;

@mixin primary_btn() {
  // Copied from au_button.scss with !important
  color: $styled_btn-color !important;
  background: $primary_btn-background-color !important;
  height: 48px !important;
  @include shadow(
    $primary_btn-shadow-color,
    $primary_btn_hover-shadow-color,
    $primary_btn-shadow-color
  );
  &:hover {
    // background: $primary_btn_hover-background-color !important;
  }
  &:active {
    // background: $primary_btn-shadow-color !important;
  }
}

.selection_bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $selection_bar_background_color;
  padding: 0 32px;
  @include mq($until: tablet) {
    flex-direction: column;
  }
}

.left_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 16px 0;
}

.right_container {
  display: flex;
  align-items: center;
  margin-left: 16px;
  @include mq($until: tablet) {
    flex-direction: column;
    margin-bottom: 16px;
  }
}

.download_button {
  margin-right: 20px;
  &:before {
    font-size: 14px;
    @include au-icon("\e021");
    margin-right: 12px;
  }
  padding: 0 32px;
  @include mq($until: tablet) {
    margin-bottom: 16px;
  }
}

.dropdown {
  width: 240px;
  text-transform: capitalize;
  margin-left: 16px;
  > ul {
    margin-top: 0;
  }
}

.service_text {
  font-weight: 600;
  @include font-size(14px, 18px);
}

.loader {
  display: flex;
  margin: 24px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.flex {
  flex: 1 0 auto;
}

.outer_container {
  border: 1px solid red;
  padding-top: unset;
  padding-left: unset;
  padding-right: unset;
  width: 660px;
  padding: 0 0 32px;
}


.container {
  :global {
    .swagger-ui .wrapper {
      max-width: unset;
      padding: 0 32px;
    }

    .swagger-ui .body-param-options label select {
      height: 48px;
      @include font-size(12px, $global-line-height-desktop);
    }

    .swagger-ui .parameter__name.required:after {
      line-height: 20px;
    }

    .swagger-ui .parameters-col_description select {
      height: 48px;
    }

    .swagger-ui .parameters-col_description p::first-letter {
      text-transform: uppercase;
    }

    .swagger-ui .parameters-col_description .markdown:first-child p {
      margin: 0 !important;
    }

    .swagger-ui .renderedMarkdown code {
      color: $code-label;
    }

    .swagger-ui .btn {
      height: 48px;
      font-family: $global-font-sans;
      font-weight: 600 !important;
      color: $link;
      border: 1px solid $link;
      @include font-size(16px, 24px);
      > svg {
        fill: $link;
      }
    }

    .swagger-ui .brace-open.object {
      @include font-size(16px, 24px)
    }

    .swagger-ui .brace-close {
      @include font-size(16px, 24px)
    }

    .swagger-ui .download-contents {
      height: 40px;
      display: flex;
      align-items: center;
      width: auto;
      top: -47px;
      right: 0;
      padding: 10px 17px 8px;
      background-color: $secondary_btn;
      @include font-size(16px, 22px);
      font-family: $global-font-sans;
    }

    .swagger-ui .parameters-col_description input[type=text] {
      height: 48px;
    }

    .swagger-ui .parameters-col_description code {
      @include font-size(16px, 24x, true);
      font-family: $global_font-mono2 !important;
      font-weight: normal !important;
      color: $value-color !important;
      line-height: 1 !important;
    }

    .swagger-ui .auth-container input[type=text] {
      height: 48px;
    }

    .swagger-ui .scheme-container .schemes>label select {
      height: 48px;
    }

    .swagger-ui .model {
      @include font-size(15px, 23px, true);
      font-family: $global-font-mono2 !important;
      > span {
        > span:not([class]) {
          position: relative;
        }
      }
    }

    .swagger-ui .model-title {
      @include font-size(16px, 24px, true);
      font-family: $global-font-sans !important;
    }

    .swagger-ui .model-toggle:after {
      @include font-size(11px, 22px);
    }

    .swagger-ui .responses-inner h4 {
      @include font-size(16px, 22px);
      color: $font_color;
    }

    .swagger-ui .responses-inner h5 {
      @include font-size(14px, 18px);
    }

    .swagger-ui .btn.authorize span {
      padding: 0 20px 0 0;
    }

    .swagger-ui .authorization__btn {
      fill: $font_color !important;
      opacity: 0.8;
    }

    .main {
      .title {
        font-family: $global-font-sans;
        @include font-size(24px, 32px);
        color: $font_color;
        text-transform: capitalize;
        small {
          // Bubble with OpenAPI version
          border-radius: 100px;
          background-color: $bubble_color;
          height: 16px;
          padding: 0 4px;
          .version {
            // Bubble text
            font-family: $global-font-sans;
            @include font-size(11px, 16px);
          }
        }
      }

      .base-url {
        // Base URL
        color: $font_color;
        @include font-size(15px, 23px);
        font-family: $global-font-mono2;
        margin-top: 6px;
      }
    }

    .description .markdown {
      h1, h2, h3, h4, h5, h6 {
        margin: 0;
        padding: 0;
        font-family: $global-font-base;
        font-weight: normal;
        @include font-size(16px, 24px);
      }

      h1, p {
        color: $font_color;
      }
      p {
        @include font-size(16px, $global-line-height-desktop);
        font-family: $global-font-base;
      }
    }

    .info__contact {
      margin-top: 32px;
    }

    .info {
      margin: 32px 0;
    }

    .info > div {
      margin: 0 !important;
    }

    .info div a {
      color: $link;
      @include font-size(16px, $global-line-height-desktop);
      font-family: $global-font-base;
    }

    h4.opblock-tag {
      @include font-size(16px, $global-line-height-desktop); // reset
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: flex-start;
      padding: 0 0 6px;
      margin: 0;
      border-bottom-color: $hr_border_color;

      + div {
        margin-top: 16px;
      }

      span {
        // Main title
        color: $font_color !important;
        @include font-size(20px, 28px, true);
        font-family: $global-font-sans !important;
      }

      small {
        // MD description
        color: $description_font_color;
        @include font-size(16px, $global-line-height-desktop);
        font-family: $global-font-base;
        padding: 0;
        p {
          margin-top: 0 !important;
          margin-bottom: 0 !important;
          line-height: 1.2 !important;
        }
      }

      .expand-operation {
        position: absolute;
        top: calc(50% - 20px);
        right: 0;
      }

      .markdown {
        color: $font_color;
        opacity: 0.6;
        margin-top: 6px;
      }
    }

    // SwaggerUI defines SVGs in the headers and uses the <use> tag to ref
    // them.  We need to add a pseudo element in its place.
    div.opblock-tag-section {
      margin-bottom: 32px;

      .expand-operation {
        width: 40px; height: 40px;
        padding: 0;
        svg {
          display: none;
        }
      }
      &.is-open {
        .expand-operation {
          &:after {
            @include au-icon("\32");
            font-size: 32px;
          }
        }
      }
      &:not(.is-open) {
        .expand-operation {
          &:after {
            @include au-icon("\30");
            font-size: 32px;
          }
        }
      }
    }

    div.opblock {
      border-radius: 0 !important;
      border-width: 1px !important;
      border-color: $box_border_color !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      box-shadow: none !important;
      margin: 0;
      &:first-child {
        margin-top: 16px;
      }
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    div.opblock-summary {
      padding: 0 !important;
    }

    .opblock.is-open .opblock-summary {
      border-color: $box_border_color !important;
    }
    div.opblock-deprecated {
      div.opblock-body {
        border-top: 1px solid !important;
        border-color: $box_border_color !important;
        @include font-size(16px, $global-line-height-desktop);
      }
    }

    span.opblock-summary-method {
      border-radius: 0 !important;
      @include font-size(16px, $global-line-height-desktop, true);
      font-family: $global-font-sans !important;
      padding: 13px 0 !important;
      width: 80px !important;
      height: 50px !important;
      font-weight: 600 !important;
      text-transform: lowercase;
      &::first-letter {
        text-transform: uppercase;
      }
    }

    // Background color for endpoint HTTP verbs
    div.opblock.opblock-get .opblock-summary .opblock-summary-method{
      background-color: $get_method_color !important;
    }
    div.opblock.opblock-patch .opblock-summary .opblock-summary-method {
      background-color: $patch_method_color !important;
    }
    div.opblock.opblock-put .opblock-summary .opblock-summary-method {
      background-color: $put_method_color !important;
    }
    div.opblock.opblock-post .opblock-summary .opblock-summary-method {
      background-color: $post_method_color !important;
    }
    div.opblock.opblock-delete .opblock-summary .opblock-summary-method {
      background-color: $delete_method_color !important;
    }
    div.opblock.opblock-deprecated .opblock-summary .opblock-summary-method {
      background-color: $deprecated_method_color !important;
    }

    .opblock.opblock-get .tab-header .tab-item.active h4 span:after {
      background: $get_method_color;
    }

    .opblock.opblock-patch .tab-header .tab-item.active h4 span:after {
      background: $patch_method_color;
    }

    .opblock.opblock-put .tab-header .tab-item.active h4 span:after {
      background: $put_method_color;
    }

    .opblock.opblock-post .tab-header .tab-item.active h4 span:after {
      background: $post_method_color;
    }

    .opblock.opblock-delete .tab-header .tab-item.active h4 span:after {
      background: $delete_method_color;
    }

    .opblock.opblock-deprecated .tab-header .tab-item.active h4 span:after {
      background: $deprecated_method_color;
    }

    span.opblock-summary-path {
      font-family: $global-font-base !important;
      color: $font_color !important;
      font-weight: normal !important;
      padding: 0 0 0 16px !important;
    }

    div.opblock-summary-description {
      @include font-size(16px, $global-line-height-desktop, true);
      font-family: $global-font-base !important;
      color: $description_font_color !important;
      padding: 0 16px !important;
      opacity: 0.5;
    }

    div.opblock-description-wrapper {
      @include font-size(16px, $global-line-height-desktop, true);
      color: $description_font_color !important;
      padding: 16px 24px;
      margin: 0;
    }

    div.opblock {
      background-color: $summary_background !important;
    }
    div.opblock.is-open {
      background-color: $open_summary_background !important;
    }

    button.try-out__btn, button.opblock-control__btn {
      color: $link !important;
      border: 1px solid $link !important;
      border-radius: 3px !important;
      &::after {
        content: '' !important;
      }
    }

    h4.opblock-title, div.opblock-section-header h4 {
      font-family: $global-font-sans !important;
      color: $font_color !important;
      font-weight: 600 !important;
      @include font-size(18px, 26px, true);
    }

    div.table-container {
      padding: 16px 24px;
    }

    table {
      margin: 0;
    }

    table thead tr {
      th, td {
        border-bottom-color: $expanded_hr_border_color !important;
        padding: 0 !important;
        @include font-size(14px, $global-line-height-desktop, true);
      }
    }

    table thead tr td {
      color: $font_color !important;
    }

    table thead tr th,
    tr.responses-header td {
      color: $font_color !important;
      @include font-size(14px, 18px, true);
      font-family: $global-font-sans;
      font-weight: 600 !important;
      padding-bottom: 12px !important;
    }

    div.opblock-body,
    td.parameters-col_name, td.parameters-col_description {
      @include font-size(16px, $global-line-height-desktop, true);
      font-family: $global-font-base;
      color: $font_color !important;
      padding: 19px 0 24px !important;
    }

    div.parameter__name {
      @include font-size(16px, $global-line-height-desktop, true);
      font-family: $global-font-base !important;
      font-weight: normal !important;
      span, &::after {
        @include font-size(10px, 20px, true);
        line-height: 1 !important;
        color: $alert !important;
        vertical-align: super;
        padding-left: 5px;
      }
      &::after {
        top: 0 !important;
        padding: 0 0 0 3px !important;
      }
    }

    table.parameters {
      div.parameter__type, div.parameter__in {
        @include font-size(15px, 23px, true);
        font-family: $global-font-mono2 !important;
        font-weight: normal !important;
        font-style: normal !important;
        padding: 0 !important;
      }
      div.parameter__in {
        color: $font_color !important;
      }
      div.parameter__type {
        color: $field-color !important;
      }
    }

    table tbody tr td:first-of-type {
      // Will keep the description columns aligned
      width: 25% !important;
    }

    div.opblock-section-header {
      box-shadow: unset !important;
      padding: 5px 24px !important;
    }

    div.scheme-container {
      padding: 0;
      margin: 0;
      box-shadow: unset;
      .schemes {
        margin: 32px 0;
        label {
          flex-direction: row;
          margin: 0;
          span {
            @include font-size(16px, $global-line-height-desktop, true);
            font-family: $global-font-base !important;
            font-weight: normal !important;
            padding: 0 24px 0 0 !important;
            color: $font_color !important;
          }
        }
      }
    }

    div.opblock-section-header, div.body-param-options {
      label {
        font-family: $global-font-base !important;
        color: $font_color !important;
        @include font-size(16px, $global-line-height-desktop, true);
        font-weight: normal !important;
        span {
          padding: 0 24px 0 0 !important;
        }
      }
    }

    div.opblock-section-header, div.body-param-options, section.schemes {
      select {
        height: 48px !important;
        border-radius: 2px !important;
        border: 1px solid $hr_border_color !important;
        background-color: $select_background_color !important;
        color: $font_color !important;
        font-family: $global-font-base !important;
        font-weight: normal !important;
        @include font-size(16px, $global-line-height-desktop, true);
      }
    }

    div.body-param-options label, section.schemes label {
      flex: 1 0 auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    ul.tab {
      padding: 16px 0 0;
      margin: 0;
    }

    ul.tab li {
      cursor: default !important;
      @include font-size(14px, $global-line-height-desktop, true);
      padding: 0 6px;

      &:first-of-type {
        padding: 0 6px;
        &:after {
          display: none;
        }
      }

      a {
        color: $label_color;
        font-family: $global-font-sans;
        font-weight: 600;
        padding: 12px 12px 8px;
        display: inline-block;
        line-height: 1;
        min-width: 56px;
        text-align: center;
        text-transform: uppercase;
        text-decoration: none;
      }

      &:hover a {
        color: $font_color !important;
      }

      &.active {
        border-bottom: 2px solid $tab_border-color !important;
        background-color: #FFFFFF;
        a {
          color: $font_color !important;
        }
      }
    }

    div.highlight-code pre {
      padding: 24px 32px;
      min-height: auto !important;
    }

    .parameters .model-box {
      display: flex;
      height: auto;
      padding: 24px 32px;
      background-color: #FFFFFF;
    }

    .opblock-body pre, textarea.curl, .swagger-ui .opblock-body pre.microlight span {
      @include font-size(15px, 23px, true);
      font-family: $global_font-mono2 !important;
      background: #FFF !important;
      color: $font_color !important;
      font-weight: normal !important;
      span {
        color: $font_color !important;
        font-weight: normal !important;
      }
    }

    // Execute buttons
    div.btn-group {
      justify-content: center !important;
      padding: 0 32px 32px !important;
      .btn {
        flex: 0 0 0 !important;
        &:first-child {
          @include primary_btn();
          margin-right: 10px !important;
          border: none !important;
        }
      }
    }

    .btn-clear {
      background-color: #FFFFFF;
    }

    div.execute-wrapper {
      text-align: center !important;
      padding: 0 !important;
      margin-bottom: 32px;
      button.btn.execute {
        @include primary_btn();
        width: auto !important;
        animation: none !important;
        border: none !important;
      }

      &:empty {
        display: none;
      }
    }

    section.models {
      margin-top: 38px;
      border-radius: 0 !important;
      border: 1px solid $box_border_color !important;
      h4 {
        height: 50px;
        color: $font_color !important;
        font-family: $global-font-sans !important;
        font-weight: 600 !important;
        @include font-size(14px, $global-line-height-desktop, true);
        text-transform: uppercase !important;
        border-bottom: 1px solid $box_border_color !important;
        margin-bottom: 20px !important;
      }
    }

    table.model {
      margin-top: 14px;
      tbody tr td {
        font-weight: normal !important;
        &:first-of-type {
          color: $font_color !important;
          padding-right: 50px !important;
        }
      }
    }

    .model-container {
      border-radius: 0;
      margin: 10px 15px;
      background-color: $models_container_bg-color;
      &:first-of-type {
        margin: 18px 15px 10px;
      }
      &:last-of-type {
        margin: 10px 15px 0;
      }
      > .model-box {
        padding: 0;
      }
      .model-toggle {
        margin: 0 6px 0 2px;
        top: 4px;
        &:after {
          margin-top: -2px;
        }

        &.collapsed {
          margin: 0 0 0 -6px;
        }
      }
    }

    span.model-box {
      padding: 10px;
    }

    span.model-title {
      @include font-size(14px, 18px, true);
      line-height: 1;
      color: $font_color !important;
    }

    span.prop-type, span.prop-enum, span.prop-format {
      font-family: $global-font-mono2 !important;
      font-weight: normal !important;
      color: $field-color !important;
    }

    .response {
      .response-col_status {
        @include font-size(15px, 23px, true);
        font-family: $global-font-mono2 !important;
        color: $font_color !important;
      }
      .response-content-type:empty {
        display: none;
      }
      .example {
        margin-bottom: 22px;
      }
      .model-box {
        display: flex;
        height: auto;
        padding: 20px 32px 24px;
        margin-bottom: 22px;
        background-color: #FFFFFF;
      }
      .response-col_status {
        padding: 19px 0 24px !important;
      }
      .response-col_description {
        padding: 6px 0 24px !important;
      }
      .response-col_description {
        h5 {
          @include font-size(16px, 22px, true);
          font-family: $global-font-sans !important;
          color: $font_color !important;
          margin: 12px 0 !important;
        }
        > div:nth-child(n+2) {
          margin-top: 24px;
        }
      }
      .response-col_description__inner {
        padding-top: 12px;
        > .markdown {
          @include font-size(15px, 23px, true);
          font-family: $global-font-mono2 !important;
          color: $response_markdown-color !important;
          background-color: $response_markdown_bg-color !important;
          font-weight: normal !important;
          font-style: normal !important;
        }
      }
    }
  }
}

.using_client {
  color: $alert;
  margin-left: 10px;
  display: flex;
  align-items: center;
}