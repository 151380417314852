///* ========================================================================
//   #GLOBAL
//   ======================================================================== */
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

@import 'node_modules/@au/core/src/css/settings/settings.global';

$global-doc-horizontal-padding: 32px;
$toc-width: 350px;
$toc-title-max-width: 300px;
$toc-level-indent: 15px;
$toc-link-level-indent: 25px;
