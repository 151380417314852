@use "sass:math";
@import "src/css/globals.scss";
@import "src/css/tools/tools.au-icon";

$body-font-size: 14px;

.overlay {
  background-color: rgba(142,169,201,0.8);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  z-index: 1;
}

.content {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 450px;
  width: 680px;
  border: 1px solid $AU_blue_shade_4;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  z-index: 2;
  background-image: url("../../images/IE11.png");
  background-repeat: no-repeat;
  background-position: 81px 85%;
  background-size: 84%;
  padding-left: 50px;
  color: $AU_blue_shade_3;

  .title {
    font-size: math.div(18px, 14px) * 1em;
    font-family: $global-font-sans;
    font-weight: 600;
    line-height: 1;
  }

  .body {
    width: 415px;
    margin-top: 15px;
    font-family: $global-font-base;
    font-size: $body-font-size;
    line-height: 23px;
  }

  .browsers {
    margin-top: 21px;

    .browser {
      margin-top: 4px;
      color: $link;
      font-size: $body-font-size;
      line-height: 1;
    }

    .browserimage {
      display: inline-block;
      height: 15px;
      width: 15px;
      position: relative;
      top: 2px;
      margin-right: 4px;
      background-size: contain;
    }
  }

  .exit {
    align-self: flex-end;
    height: 25px;
    margin: 20px 21px 0 0;
    font-size: 25px;
    line-height: 1;
    color: $secondary_btn;
    &:before {
      @include au-icon("\66");
    }
    &:hover {
      cursor: pointer;
    }
  }
}

.microsoftEdge {
  background-image: url("../../images/microsoftEdge_128x128.png");
}

.googleChrome {
  background-image: url("../../images/googleChrome_128x128.png");
}

.mozillaFirefox {
  background-image: url("../../images/mozillaFirefox_128x128.png");
}
