@import 'src/css/globals.scss';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'src/css/tools/_tools.au-icon';
@import 'src/css/tools/_tools.banner';
@import 'node_modules/sass-mq/mq';

$documentation-width: 500px;
$default-font-color: #324661;
$default-font-family: $global-font-sans;
$secondary-font-color: #006699;
$secondary-font-family: $global-font-base;
$table-head-background-color: $AU_blue_shade_6;
$table-odd-row-background-color: $AU_gray_shade_3;
$code-background-color: $AU_gray_shade_3;
$code-tabs-background-color: $AU_blue_shade_4;
$code-tab-selected-color: $AU_blue_shade_3;
$code-tab-font-color: #fff;
$topic-color: $AU_gray_shade_2;
$skeleton_screen-color: $AU_gray_shade_2;
$skeleton_second_screen-color: $AU_gray_shade_3;

$banner-info-border-color: #1C96CF;
$banner-info-field-color: #EEF7FF;
$banner-warning-border-color: #FFCD5D;
$banner-warning-field-color: #FFF7E7;
$banner-alert-border-color: #A22330;
$banner-alert-field-color: #FFF2F2;
$banner-success-border-color: #357262;
$banner-success-field-color: #EDF7EB;
$banner-keypoint-border-color: #7F198D;
$banner-keypoint-field-color: #ECEFFF;
$banner-keyterm-border-color: #CC5280;
$banner-keyterm-field-color: #FBF1FC;
$banner-objective-border-color: #1188AA;
$banner-objective-field-color: #E9FAF7;
$banner-download-border-color: $primary_btn;
$banner-download-field-color: #F5F5F5;

$code-editor-result-background-color: #fcfbf7;

$json-attr-color: #5cae02;
$json-string-color: #425773;
$json-number-color: #425773;

$topic-width: 310px;
$topic-two-width: 225px;
$topic_mobile-width: 200px;
$topic-height: 20px;
$topic-position: 24px 10px;
$topic-two-position: 24px 60px;
$topic-skeleton: linear-gradient($topic-color $topic-height, transparent 0);

$subtopic-width: calc(100vw - 427px);
$subtopic_ls-width: calc(100vw - 418px);
$subtopic_tablet-width: calc(100vw - 48px);
$subtopic_two-width: calc(100vw - 726px);
$subtopic_two_ls-width: calc(100vw - 518px);
$subtopic_two_tablet-width: calc(100vw - 168px);
$subtopic_two_mobile-width: calc(100vw - 125px);
$subtopic_four-width: calc(100vw - 958px);
$subtopic_four_ls-width: calc(100vw - 618px);
$subtopic_four_tablet-width: calc(100vw - 418px);
$subtopic_four_mobile-width: calc(100vw - 165px);
$subtopic-height: 15px;
$subtopic-position: 24px 105px;
$subtopic_two-position: 24px 131px;
$subtopic_three-position: 24px 169px;
$subtopic_four-position: 24px 195px;
$subtopic-skeleton: linear-gradient($skeleton_screen-color $subtopic-height, transparent 0);
$topic_tablet-position: 24px 41px;
$subtopic_tablet-position: 24px 103px;
$subtopic_two_tablet-position: 24px 139px;
$subtopic_three_tablet-position: 24px 187px;
$subtopic_four_tablet-position: 24px 223px;


.documentation {
  // Central documentation
  padding-bottom: 18px;
  font-family: $global-font-base;
  width: 100%;
  @include mq($until: tablet) {
    max-width: 612px;
  }
  @include mq(tablet, tablet-ls) {
    max-width: 632px;
  }
  @include mq($from: tablet-ls) {
    max-width: 888px;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: $default-font-family;
  }

  h2, h3, h4 {
    margin-bottom: 5px;
  }

  h1 {
    color: $AU_blue_shade_0;
    margin: 41px auto 66px auto;
    @include font-size(47px, 74px);
    @include mq(tablet, desktop) {
      @include font-size(45px, 70px);
      margin: 35px auto 60px auto;
    }
    @include mq($from: desktop) {
      @include font-size(42px, 66px);
      margin: 31px auto 56px auto;
    }
  }

  h2 {
    @include font-size(29px, 47px);
    color: $secondary-font-color;
    margin-bottom: 21px;
    margin-top: 50px;
    @include mq(tablet, desktop) {
      @include font-size(28px, 45px);
      margin-bottom: 18px;
      margin-top: 47px;
    }
    @include mq($from: desktop) {
      margin-bottom: 17px;
      margin-top: 44px;
      @include font-size(26px, 42px);
    }
  }

  h3 {
    @include font-size(23px, 38px);
    margin-top: 31px;
    margin-bottom: 11px;
    @include mq($until: tablet) {
      margin-bottom: 12px;
    }
    @include mq(tablet, desktop) {
      @include font-size(22px, 36px);
      margin-top: 29px;
    }
    @include mq($from: desktop) {
      @include font-size(20px, 33px);
      margin-top: 27px;
    }
    color: $secondary-font-color;
  }

  h4 {
    // Using as the header above a code block for now
    @include font-size(18px, 31px);
    font-weight: 600;
    margin-bottom: 7px;
    @include mq($until: tablet) {
      margin-top: 31px;
    }
    @include mq(tablet, desktop) {
      @include font-size(17px, 29px);
    }
    @include mq($from: desktop) {
      @include font-size(16px, 27px);
      margin-top: 27px;
    }
  }

  h5 {
    font-weight: 600;
    margin-bottom: 6px;
    @include font-size(16px, 27px);
    @include mq($until: tablet) {
      margin-bottom: 7px;
    }
    @include mq(tablet, desktop) {
      @include font-size(15px, 25px);
      margin-bottom: 6px;
    }
    @include mq($from: desktop) {
      @include font-size(14px, 24px);
    }
  }

  h6 {
    font-weight: 600;
    margin-bottom: 6px;
    @include font-size(14px, 24px);
    @include mq(tablet, desktop) {
      @include font-size(13px, 22px);
    }
    @include mq($from: desktop) {
      @include font-size(12px, 22px);
    }
  }

  h2 + p, h3 + p, h4 + p, h5 + p, h6 + p {
    margin-top: 0;
  }

  p {
    font-family: $secondary-font-family;
    margin-bottom: 31px;
    @include mq(tablet, desktop) {
      margin-bottom: 29px;
    }
    @include mq($from: desktop) {
      margin-bottom: 27px;
    }
  }

  table {
    max-width: 100%;
  }

  thead {
    background-color: $table-head-background-color;
  }

  th {
    @include font-size(14px, 18px);
    font-family: $default-font-family;
    font-weight: 600;
    padding: 11px 24px;
    text-align: left;
    @media print {
      padding: 11px 4px;
    }
  }

  td {
    padding: 11px 24px;
    @media print {
      padding: 11px 4px;
    }
  }

  tr:nth-of-type(even) {
    background-color: $table-odd-row-background-color;
  }

  ul, ol {
    font-family: $secondary-font-family;
    margin-left: 31px;
    margin-top: 8px;
    margin-bottom: 15px;
    @include mq(tablet, desktop) {
      margin-left: 29px;
      margin-bottom: 13px;
    }
    @include mq($from: desktop) {
      margin-left: 27px;
      margin-bottom: 11px;
    }
    li {
      p {
        margin-top: 0px;
      }
    }
  }

  li {
    margin-bottom: 10px;
    @include mq(tablet, desktop) {
      margin-bottom: 8px;
    }
    @include mq($from: desktop) {
      margin-bottom: 6px;
    }
  }

  blockquote {
    @include au-banner('\2e', $banner-info-border-color, $banner-info-field-color);
    padding-top: 24px;
    padding-bottom: 24px;
  }

  blockquote p {
    margin-top: 0;
    display: inline;
  }

  ol + ul, p + ul {
    margin-top: 0;
    margin-left: 30px;
    li {
      padding-left: 0;
    }
  }

  ul + ol, li > ul, li > ol {
    margin-top: 0;
  }

  pre {
    background-color: $code-background-color;
    white-space: pre-wrap;
    padding: 28px;
    @include mq(tablet, desktop) {
      padding: 26px;
    }
    @include mq($from: desktop) {
      padding: 24px;
    }
  }

  code {
    font-family: $global-font-mono2;
    background-color: $code-background-color;
    @include font-size(18px, 30px);
    @include mq(tablet, desktop) {
      @include font-size(17px, 29px);
    }
    @include mq($from: desktop) {
      @include font-size(16px, 27px);
    }
  }

}

:global {
  .table-wrapper {
    // overflow-x: auto;
  }
}

.selected {
  background-color: $code-tab-selected-color;
}

.container {
  display: flex;
  flex-direction: column;
  @include font-size(18px, 31px);
  overflow: auto;
  @include mq($from: tablet) {
    max-height: calc(100vh - 137px);
    width: 100%;
    align-items: center;
  }
  @include mq(tablet, desktop) {
    @include font-size(17px, 29px);
  }
  @include mq($from: desktop) {
    @include font-size(16px, 27px);
  }
  @media screen {
    :global .document-legalese {
      display: none;
    }
  }

  @media print {
    max-height: none;
    max-width: none;
    overflow-y: visible;
    margin-left: 0;
    margin-top: 40px; // give room to legalese::before
    :global .document-legalese {
      &::before {
        position: absolute;
        top: 0;
        content: attr(data-content);
      }
    }
  }
 }

:global {
  .right-aligned-image-25 {
    @include mq($from: desktop){
      width: 25%;
      float: right;
    }
    @include mq($until: desktop) {
      width: 100%;
      display:block;
    }
    margin: 5px 0 5px 15px;
  }
  .right-aligned-image-50 {
    @include mq($from: desktop){
      width: 60%;
      float: right;
    }
    @include mq($until: desktop) {
      width: 100%;
      display: block;
    }
    margin: 5px 0 5px 15px;
  }
}

.policy {
  justify-content: flex-start;
  @media print {
    display: none;
  }
}

.skeleton_container {
  height: 100vh;
  padding-top: 85px;
  margin: 0 auto;
  @include mq($until: tablet) {
    max-width: 612px;
  }
  @include mq(tablet, tablet-ls) {
    max-width: 632px;
  }
  @include mq($from: tablet-ls) {
    max-width: 888px;
  }
}

.skeleton {
  height: 16px;
  border-radius: 13px;
  @include mq($until: tablet) {
    max-width: 612px;
  }
  @include mq(tablet, tablet-ls) {
    max-width: 632px;
  }
  @include mq($from: tablet-ls) {
    max-width: 888px;
  }
}

.skeleton_one {
  composes: skeleton;
  height: 32px;
  width: 338px;
  background-color: $skeleton_screen-color;
}

.skeleton_two {
  composes: skeleton;
  height: 24px;
  width: 193px;
  margin-top: 82px;
  background-color: $skeleton_screen-color;
}

.skeleton_three {
  composes: skeleton;
  width: 586px;
  margin-top: 33px;
  background-color: $skeleton_second_screen-color;
}

.skeleton_four {
  composes: skeleton;
  width: 880px;
  margin-top: 15px;
  margin-bottom: 36px;
  background-color: $skeleton_second_screen-color;
}
.skeleton_five {
  composes: skeleton;
  width: 373px;
  margin-top: 30px;
  background-color: $skeleton_second_screen-color;
}

/* Global styles for highlight.js - consider moving */
/* ref: http://highlightjs.readthedocs.io/en/latest/css-classes-reference.html */
:global {
  .language-json {
    .hljs-attr {
      color: $json-attr-color;
    }
    .hljs-string {
      color: $json-string-color;
    }
    .hljs-number {
      color: $json-number-color;
    }
  }

  .language-bash, .language-c\+\+, .language-http, .language-java, .language-javascript, .language-json, .language-properties, .language-protobuf, .language-python, .language-shell, .language-yaml {
    .hljs {
      display: block;
      overflow-x: auto;
      padding: 0.5em;
      background: #fff;
      color: black;
    }

    .hljs-comment,
    .hljs-quote {
      color: #006a00;
    }

    .hljs-keyword,
    .hljs-selector-tag,
    .hljs-literal {
      color: #aa0d91;
    }

    .hljs-name {
      color: #008;
    }

    .hljs-variable,
    .hljs-template-variable {
      color: #660;
    }

    .hljs-string {
      color: #c41a16;
    }

    .hljs-regexp,
    .hljs-link {
      color: #080;
    }

    .hljs-title,
    .hljs-tag,
    .hljs-symbol,
    .hljs-bullet,
    .hljs-number,
    .hljs-meta {
      color: #1c00cf;
    }

    .hljs-section,
    .hljs-class .hljs-title,
    .hljs-type,
    .hljs-attr,
    .hljs-built_in,
    .hljs-builtin-name,
    .hljs-params {
      color: #5c2699;
    }

    .hljs-attribute,
    .hljs-subst {
      color: #000;
    }

    .hljs-formula {
      background-color: #eee;
      font-style: italic;
    }

    .hljs-addition {
      background-color: #baeeba;
    }

    .hljs-deletion {
      background-color: #ffc8bd;
    }

    .hljs-selector-id,
    .hljs-selector-class {
      color: #9b703f;
    }

    .hljs-doctag,
    .hljs-strong {
      font-weight: bold;
    }

    .hljs-emphasis {
      font-style: italic;
    }
  }
}

// live editing feedback
:global {
  .bad-download-link {
    background-color: #c41a16;
    &::after {
      content: ' !!bad download link!!';
    }
  }
}

:global {
  p.banner-warning, div.banner-warning {
    @include au-banner('~', $banner-warning-border-color, $banner-warning-field-color);
    padding-top: 24px;
    padding-bottom: 24px;
  }
  p.banner-info, div.banner-info {
    @include au-banner('\2e', $banner-info-border-color, $banner-info-field-color);
    padding-top: 24px;
    padding-bottom: 24px;
  }
  p.banner-alert, div.banner-alert {
    @include au-banner('^', $banner-alert-border-color, $banner-alert-field-color);
    padding-top: 24px;
    padding-bottom: 24px;
  }
  p.banner-success, div.banner-success {
    @include au-banner('}', $banner-success-border-color, $banner-success-field-color);
    padding-top: 24px;
    padding-bottom: 24px;
  }
  p.banner-keypoint, div.banner-keypoint {
    @include au-banner('_', $banner-keypoint-border-color, $banner-keypoint-field-color);
    padding-top: 24px;
    padding-bottom: 24px;
  }
  p.banner-keyterm, div.banner-keyterm {
    @include au-banner('`', $banner-keyterm-border-color, $banner-keyterm-field-color);
    padding-top: 24px;
    padding-bottom: 24px;
  }
  p.banner-objective, div.banner-objective {
    @include au-banner('|', $banner-objective-border-color, $banner-objective-field-color);
    padding-top: 24px;
    padding-bottom: 24px;
  }
  p.banner-download, div.banner-download {
    @include au-banner('\e021', $banner-download-border-color, $banner-download-field-color);
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

.tablet {
  max-width: 674px;
  width: 100%;
  @include mq($until: tablet) {
    padding: 0 45px;
  }
  @include mq($from: tablet) {
    padding: 0 15px;
  }
}

.mobile {
  padding: 0 48px;
  @include mq(tablet, tablet-ls) {
    padding: 0 180px;
  }
}
