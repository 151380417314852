@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'src/css/tools/_tools.au-icon';

// Format the contents of markdown banners
@mixin au-banner($icon-text, $border-color, $field-color) {
  border-left: 5px solid $border-color;
  background-color: $field-color;
  padding: 13px 13px 13px 56px;
  position: relative;
  &::before {
    @include au-icon($icon-text);
    position: absolute;
    display: block;
    height: 100%;
    left: 16px;
    top: calc(50% - 12px);
    color: $border-color;
    font-size: 24px;
    display: inline-block;
  }
}
