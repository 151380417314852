@import 'src/css/globals.scss';
@import 'src/css/tools/tools.font-size';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/sass-mq/mq';

$background-color: $AU_gray_shade_3;
$background_hover-color: #FFFFFF;
$background_hover_shadow-color: #9B9B9B;
$font-color: $AU_blue_shade_3;
$description-font-color: $AU_blue_shade_2;
$view-font-color: $link; // TODO: make this adjustable
$indicator-color: $AU_blue_shade_1;
$indicator-font-color: #FFFFFF;
$tile-border-color: #4A90E2;

$tile-width: 368px;
$tile-width-tablet: 348px;
$tile-width-desktop: 394px;
$tile-width-mobile: calc(100vw - 48px); // shared

$margin: 24px;
$width: 290px;
$width-mobile: calc(100vw - #{$margin} * 2);

.container {
  flex: 0 1 auto;
  border-top: 3px solid $tile-border-color;
  margin: 0 12px 24px;
  background-color: $background_color;
  display: flex;
  flex-direction: column;
  position: relative;
  &:hover {
    background-color: $background_hover-color;
    box-shadow: 0 0 8px 0 $background_hover_shadow-color;
  }
  @include mq($until: tablet) {
    width: $tile-width-mobile;
      }
  @include mq(tablet, tablet-ls) {
    width: $tile-width-tablet;
  }
  @include mq(tablet-ls, large-desktop) {
    width: $tile-width-desktop;
  }
  @include mq($from: large-desktop) {
    width: $tile-width;
  }
}

.container.services {
  margin: 0 12px $margin;

  @include mq($until: tablet) {
    width: $width-mobile;
  }
  @include mq($from: tablet) {
    width: $width;
  }
}

.tile {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  padding: 24px 24px 36px;
  &:hover {
    text-decoration: none;
  }
}

.header {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.title {
  color: $font-color;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  overflow-y: hidden; // for some reason the container is smaller than the title...
}

.indicator {
  position: absolute;
  right: 0;
  top: -3px; //design calls for placement to be on top of card top border
  color: $indicator-font-color;
  background-color: $indicator-color;
  padding: 4px 10px;
  line-height: 14px;
}

.services .title {
  @include font-size(20px, 28px);
  font-weight: 600;
  font-family: $global-font-sans;
}
.services .indicator {
  @include font-size(10px, 14px);
  font-weight: 600;
  font-family: $global-font-sans;
}
.getting-started .title {
  @include font-size(39px, 53px);
  font-family: $global-font-sans;
  font-weight: 600;
}

.view {
  position: absolute;
  bottom: 12px;
  right: 12px;
  display: flex;
  align-items: center;
  font-family: $global-font-base;
  color: $view-font-color;
  &:hover {
    text-decoration: none;
  }
  &:visited {
    color: $view-font-color;
  }
  &::after {
    color: lighten($view-font-color, 30%);
    @include au-icon("\55"); // caret-right
    margin-left: 3px;
    @include font-size(20px, 20px);
  }
}

.description {
  color: $description-font-color;
  font-family: $global-font-base;
  margin-bottom: 19px;
  @include mq(tablet, desktop) {
    margin-bottom: 18px;
  }
  @include mq($from: desktop) {
    margin-bottom: 17px;
  }
}
