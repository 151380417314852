@import 'src/css/globals.scss';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';

$highlights-line-height: 20px;
$nav-height: 54px; // same as <Navigation>
$light-slate: #969faa;


.nav_link {
  margin-left: -12px; // reclaim from navigation by the side
}

.container {
  &.expanded {
    position: relative;
    @include mq(tablet, tablet-ls) {
      position: fixed;
      top: 0;
      right: 141px;
    }
  }
  @include mq($until: tablet) {
    // TODO: support search on mobile devices
    display: none;
  }
  @include mq(tablet, tablet-ls) {
    position: fixed;
    top: 1px;
    right: 97px;
  }
}

.icon {
  width: 42px;
  height: $nav-height;
  padding: 0 8px 0 12px; // based on 24px right margin from <Navigation>
  cursor: pointer;
  &:after {
    @include au-icon("\72");
    @include font-size(18px);
    position: relative;
    top: 13px;
    right: -1px;
    @include mq(tablet, tablet-ls) {
      color: $secondary_btn;
    }
  }
}
.expanded .icon {
  display: none;
}

.search_container {
  visibility: hidden;
  // width is calculated with JS
  position: absolute;
  height: $nav-height;
  right: -44px; // 44px for "TMC" logo
}
.expanded .search_container {
  visibility: visible;
  transition: width 100ms linear; // TODO: get this to work without sliding
}

.search_box {
  position: relative;
  top: 8px;
  margin: 0;
  width: 100%;
  color: $AU_blue_shade_2;
  font-family: $global-font-base;
  overflow-x: hidden; // to mitigate the "sliding" glass
  @include font-size(16px, 24px);
  // override specific AuInput classes
  input[type="text"] {
    height: 40px;
    line-height: 40px;
    &:focus {
      background-color: #ffffff;
    }
  }
  button {
    top: unset;
  }
}

.hit_container {
  max-height: 0; // 100% when has hits
  transition: max-height 80ms linear;
  overflow-y: scroll;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
  position: relative;
  top: 6px;
  z-index: 20;
  // override global nav CSS
  text-transform: none;
  background: #ffffff;
}
.expanded.has_hits .hit_container {
  max-height: 649px;
  border: 1px solid $AU_gray_shade_1;
}

.hit_container:not(:hover) .hit.selected {
  background-color: $hover;
}

.hit {
  padding: 20px 25px;
  display: block;
  &.viewing {
    background-color: $AU_blue_shade_7;
  }
  &:hover {
    text-decoration: none;
    background-color: $hover;
  }
  &:visited {
    text-decoration: none;
  }

  .header {
    margin-bottom: 8px;
    line-height: 2;
  }

  .title {
    display: inline-block;
    color: $AU_blue_shade_2;
    font-family: $global-font-base;
    @include font-size(16px, 18px);
    font-weight: bold;
    white-space: nowrap;
    margin-right: 16px;
    margin-top: 8px;
    em {
      color: $link;
      font-style: normal;
    }
  }

  .path {
    display: inline-flex;
    flex-wrap: wrap;
    position: relative;
    top: -2px;
    text-transform: uppercase;
    > span {
      margin-top: 8px;
      color: #ffffff;
      font-family: $global-font-sans;
      white-space: nowrap;
      @include font-size(10px, 14px);
      font-weight: bold;
      background-color: $AU_blue_shade_1;
      padding: 4px 11px;
      margin-right: 8px;
    }
    > span:last-child {
      margin-right: 0;
    }
  }

  .highlights {
    color: $AU_blue_shade_2;
    font-family: $global-font-base;
    @include font-size(14px);
    line-height: $highlights-line-height;
    text-transform: none;
    max-height: 3 * $highlights-line-height;
    // multiline ellipsis
    display: block; // fallback for non-wekbit and firefox
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    em {
      color: $link;
      font-weight: normal;
      font-style: normal;
    }
  }
}

.hit:first-child {
  padding-top: 16px;
}
.hit:last-child {
  padding-bottom: 16px;
}

.search_title {
  display: flex;
  align-items: center;
  color: #8ea9c9;
  &:hover {
    background-color: $secondary_btn;
    color: #ffffff;
  }
}

.search_text {
  @include font-size(14px, 16px);
  display: inline-flex;
  padding-right: 12px;
  font-weight: 600; 
  font-family: Roboto;
}

.hidden {
  display: none;
}