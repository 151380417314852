.copy_btn {
  position: relative;
  float: right;
  top: 10px;
  right: 10px;
}

.code_block {
  padding-top: 60px;
}
