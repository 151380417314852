@import 'src/css/globals.scss';
@import 'src/css/tools/tools.au-icon';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/sass-mq/mq';

$input-color: $AU_blue_shade_3;
$input_hover-color: $secondary_btn;

$container_bg-color: #FFFFFF;
$container_border-color: $AU_blue_shade_4;

$success_banner-color: #618B32;

.container {
  overflow: scroll;
  @include mq($until: mobile-ls) {
    width: 300px;
    height: 400px;
  }
  width: 580px;
  height: 250px;
  @include mq(mobile-ls, tablet) {
    padding: 10px 40px;
  }
  @include mq($from: tablet) {
    height: 471px;
  }
}

.success_container {

  @include mq(mobile, mobile-ls) {
    width: 260px;
  }
  @include mq(mobile-ls, tablet) {
    width: 400px;
  }
  @include mq($from: tablet-ls) {
    height: 236px;
  }
  width: 580px;
  padding: 40px;
  background: $container_bg-color;
  border: 1px solid $container_border-color;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
}

.subscribe {
  @include mq($until: mobile-ls) {
    width: 100%;
  }
  @include mq($until: tablet) {
    height: 48px;
  }
  @include mq($from: mobile-ls) {
    width: 281px;
  }
}

.subscribe_container {
  @include mq($until: tablet) {
    font-size: 16px;
  }
  @include mq($until: tablet) {
    width: 100%;
  }
}

.header {
  @include font-size(20px, 28px);
  margin-bottom: 20px;
  &:before {
    @include au-icon("\e031");
    font-size: 18px;
    position: relative;
    top: -1px;
    margin-right: 10px;
  }
}

.success_header {
  composes: header;
  &:before {
    @include au-icon("\7d");
    font-size: 24px;
    position: relative;
    margin-right: 10px;
    top: 1px;
    color: $success_banner-color
  }
}

.success_subheader, .signoff {
  @include font-size(15px, 22px);
}

.success_subheader {
  margin-top: 30px;
}

.signoff {
  margin-top: 30px;
}

.close {
  position: relative;
  top: -90px;
  left: 555px;
  @include mq(mobile, mobile-ls) {
    top: -180px;
    left: 240px;
  }
  @include mq(mobile-ls, tablet) {
    top: -125px;
    left: 380px;
  }
  &:after {
    @include au-icon("\65");
    font-size: 14px;
  }
  &:hover {
    cursor: pointer;
    color: $visited;
  }
}

.subheader {
  margin-bottom: 5px;
}

.message {
  @include font-size(12px, 16px);
  padding: 15px 0;
  border-bottom: 1px solid $AU_gray_shade_1
}

.input {
  border: 1px solid $AU_blue_shade_4;
  border-radius: 3px;
  width: 100%;
}

.confirmation_message {
  @include font-size(16px, 24px);
}

.checkbox_container {
  display: flex;
  margin-top: 20px;
}

.checkbox {
  margin-top: 5px;
}

.clear {
  position: absolute;
  color: $input-color;
  right: 20px;
  height: 14px;
  line-height: 14px;
  width: 14px;
  top: 15px;
  border: none;
  background: none;
  &:after {
    @include au-icon("\65");
    font-size: 14px;
  }
  &:hover {
    text-decoration: none;
    color: $input_hover-color;
  }
}

.input_container {
  display: flex;
  position: relative;
}

.subscribe:before {
  @include au-icon("\e031");
  font-size: 14px;
  position: relative;
  margin-right: 10px;
}

.subscription {
  &:before {
    @include au-icon("\e031");
    font-size: 13px;
  }
}

.tablet {
  display: none;
}