@import "src/css/globals.scss";
@import 'node_modules/sass-mq/mq';
@import 'src/css/tools/tools.au-icon';

.services {
  color: #fff;

  div {
    border-bottom: 0 !important;
  }

  > div {
    flex: 1 0 auto;
    white-space: nowrap;
    left: 0;
    min-width: calc(100% + 32px);
  }

  // keep in .services for specificity
  .active {
    @include mq($from: tablet-ls) {
      background-color: #479aa7 !important;
    }
  }
}

.highlighted {
  composes: services;
  span {
    color: #fff;
  }
}

.swagger {
  @include mq($until: tablet-ls) {
    display: none;
  }
}

.docs:not(:last-child) {
  > span:before {
    margin-right: 8px;
    @include au-icon("\e02b");
  }
  margin-left: auto !important; // important because of specificity
  + div { // .usernavlink
    margin-left: unset !important; // important because of specificity
  }
}

.link:last-child {
  margin-left: unset !important;
  /* */
}