@import 'src/css/globals.scss';
@import 'src/css/tools/_tools.au-icon';
@import 'src/css/tools/_tools.font-size';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/sass-mq/mq';

$toc-hover-background-color: #16263d;
$toc-background-color: $AU_gray_shade_3;
$toc-font-color: $AU_blue_shade_2;
$toc-viewing-font-color: $AU_blue_gray_1;
$nav-bar-height: 54px;

$scroll_bg-color: $AU_blue_gray_1;
$scroll_thumb_bg-color: $AU_blue_shade_5;

$skeleton_screen-color: $AU_gray_shade_1;
$toc-color: $AU_blue_gray_1;
$light_block-color: $AU_gray_shade_1;
$dark_block-color: $AU_gray_shade_3;
$opacity: 0.4;
$border-radius: 13px;

$header-width: 262px;
$header-height: 15px;
$header-position: 43px 31px;
$header_two-position: 43px 93px;
$header-skeleton: linear-gradient(rgba($skeleton_screen-color, $opacity) $header-height, transparent 0);

$subpoint-width: 178px;
$subpoint-height: 15px;
$subpoint-position: 43px 63px;
$subpoint-skeleton: linear-gradient(rgba($skeleton_screen-color, $opacity) $header-height, transparent 0);

$light_block-width: 350px;
$dark_block-width: 350px;
$light_block-height: 43px;
$dark_block-height: 100vh;
$light_block-position: 0 0;
$dark_block-position: 0 43px;
$light_block-skeleton: linear-gradient($light_block-color $light_block-height, transparent 0);
$dark_block-skeleton: linear-gradient($dark_block-color $dark_block-height, transparent 0);

$toc-link-level-indent-first: 48px;
$toc-header-link-level-indent: 16px;

.wrapper {
  font-family: $global-font-base;
  width: 100vw;
  bottom: 0;
  z-index: 2;
  @media print {
    display: none;
  }
  @include mq($until: mobile-ls) {
    width: 94vw;
  }
}

.icon {
  display: flex;
  align-items: center;
  &:before {
    margin-right: 9px;
  }
}

.container {
  @include mq($until: mobile-ls) {
    width: 94vw;
  }
  @media print {
    display: none;
  }

  background-color: $toc-background-color;
  z-index: -1;
  border-right: 1px solid $toc-color;
  border-left: 1px solid $toc-color;
  width: $toc-width;
  min-height: calc(100vh - #{$nav-bar-height});

  a.anchorLink {
    display: block;
    color: $toc-font-color;
    padding-right: 28px;
    font-weight: 400;
    @include font-size(14px, 28px);

    > div {
      /* autoprefixer: off */
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      cursor: pointer;
      width: calc(100% - 50px);
      line-height: 20px;
      margin-top: 14px;
    }

    &:hover {
      text-decoration: none;
      color: $link;

    }
  }

  > div {
    top: 0;
  }

  .nav {
    max-height: calc(100vh - 54px);
    overflow: hidden;
    padding-bottom: 50px;
    padding-top: 10px;
    :global {
      .ps__rail-y {
        .ps__thumb-y {
          background-color: $scroll_thumb_bg-color;
        }
        &:hover, &.ps--clicking {
          opacity: 1;
          background-color: $scroll_bg-color;
          .ps__thumb-y {
            background-color: rgba($scroll_thumb_bg-color, 0.6);
          }
        }
      }
    }
  }
}

.skeleton_container {
  height: 100vh;
  width: 100%;
  padding-top: 35px;
  padding-left: 50px
}

.skeleton {
  height: 15px;
  opacity: .4;
  background-color: $skeleton_screen-color;
  border-radius: 13px;
}

.skeleton_one {
  composes: skeleton;
  width: 250px;
}

.skeleton_two {
  composes: skeleton;
  width: 178px;
  margin-top: 20px;
}

.link-lvl {
  position: relative;
}
.link-lvl-1 {
  composes: link-lvl;
  left: $toc-link-level-indent-first;
  max-width: $toc-title-max-width - $toc-link-level-indent-first - 28px; /*padding right*/
}
.link-lvl-2 {
  composes: link-lvl;
  left: $toc-link-level-indent-first + $toc-link-level-indent;
  max-width: $toc-title-max-width - $toc-link-level-indent-first - $toc-link-level-indent;
}
.link-lvl-3 {
  composes: link-lvl;
  left: $toc-link-level-indent-first + $toc-link-level-indent * 2;
  max-width: $toc-title-max-width - $toc-link-level-indent-first - $toc-link-level-indent * 2;
}
.link-lvl-4 {
  composes: link-lvl;
  left: $toc-link-level-indent-first + $toc-link-level-indent * 3;
  max-width: $toc-title-max-width - $toc-link-level-indent-first - $toc-link-level-indent * 3;
}
.link-lvl-5 {
  composes: link-lvl;
  left: $toc-link-level-indent-first + $toc-link-level-indent * 4;
  max-width: $toc-title-max-width - $toc-link-level-indent-first - $toc-link-level-indent * 4;
}
.link-lvl-6 {
  composes: link-lvl;
  left: $toc-link-level-indent-first + $toc-link-level-indent * 5;
  max-width: $toc-title-max-width - $toc-link-level-indent-first + $toc-link-level-indent * 5;
}

// document header links
// h1 headers, AKA lvl-1, are not shown in the toc
.lvl-2 {
  padding-left: $toc-link-level-indent;
}
.lvl-3 {
  padding-left: $toc-link-level-indent * 2;
}
.lvl-4 {
  padding-left: $toc-link-level-indent * 3;
}
.lvl-5 {
  padding-left: $toc-link-level-indent * 4;
}
.lvl-6 {
  padding-left: $toc-link-level-indent * 5;
}

.viewinganchor {
  background-color: $toc-viewing-font-color !important
}

.custom_collapser {
  @include mq($from: mobile-ls) {
    max-width: 350px;
  }
  > div {
    top: 32px;
  }
}
